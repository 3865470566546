import { PayConfigTypeEnum } from "@/layouts/components/joinHistoryModal/types";
import { AxiosRequestConfig } from "axios";
import { CancelToken } from "axios";
import http, { catch_http, httpPlus } from "./request";
import { isDesk } from "@/utils/utils";
import { IRewriteDetail } from "@/pages/chat-paper-modify/type";

// 用户会话记录添加
export const conversationAdd = (data: object) =>
  catch_http("post", "/chat-server/api/user/conversation-record/add", data);

// 用户会话记录删除（已弃用）
export const conversationDel = (id: string) =>
  catch_http("post", "/chat-server/api/user/conversation-record/delete/" + id);

// 用户会话记录删除
export const conversationBatchDel = (data: string[]) =>
  catch_http("post", "/chat-server/api/user/conversation-record/delete-batch", {
    ids: data,
  });
// 用户消息记录删除
export const conversationMessageDel = (id: string) =>
  catch_http(
    "get",
    "/chat-server/api/user/conversation-record/delete-message/" + id,
  );
// 用户会话记录修改
export const conversationUpdate = (id: string, data: object) =>
  catch_http(
    "post",
    "/chat-server/api/user/conversation-record/update/" + id,
    data,
  );

// 用户会话记录列表
export const conversationPageList = (data: object) =>
  catch_http(
    "post",
    "/chat-server/api/user/conversation-record/page-of-list",
    data,
  );

// 会话记录置顶
export const pinConversation = (id: string) =>
  catch_http("post", "/chat-server/api/user/conversation-record/chat/pinned", {
    id,
  });
// 取消置顶
export const cancelPinConversation = (id: string) =>
  catch_http(
    "post",
    "/chat-server/api/user/conversation-record/chat/cancel-pinned",
    { id },
  );

// 用户会话获取随机的SessionId
export const conversationSessionId = () =>
  catch_http(
    "post",
    "/chat-server/api/user/conversation-record/query-sessionId",
  );

// 用户消息会话历史记录
export const conversationHistory = (
  data: object,
  config?: AxiosRequestConfig,
) => catch_http("post", "/chat-server/api/chat/list-of-history", data, config);

// 用户消息会话历史记录
export const getShareList = (data: object, config?: AxiosRequestConfig) =>
  catch_http(
    "post",
    "/chat-server/api/share/v2/chat/load/context-change",
    data,
    config,
  );
// 用户消息会话历史记录
export const getpreviewChangeList = (
  data: object,
  config?: AxiosRequestConfig,
) =>
  catch_http(
    "post",
    "/chat-server/api/share/v2/after/chat-preview-change",
    data,
    config,
  );

// 客服常见问题列表
export const customerList = (data: object) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? "/desk" : ""}/faq/faq-record/page-of-list`,
    data,
  );

// 客服信息
export const customerInfo = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/faq/faq-record/faq-info`,
  );

// chat预设prompt
export const promptInit = () =>
  catch_http(
    "post",
    `/chat-server${
      isDesk() ? "/desk" : ""
    }/chat/preset-prompt-record/list-of-preset`,
  );

// 获取全部会话记录根本menuId
export const getAllMsgs = (id: string) =>
  http(
    "post",
    `/chat-server/api/user/conversation-record/list-all-session/${id}`,
  );
// 清除用户所有会话记录
export const chatConversationClear = () =>
  catch_http("post", "/chat-server/api/user/conversation-record/clear");

// 添加导出任务
export const conversationExportRecordAdd = () =>
  http("post", "/chat-server/api/user/conversation-export-record/add");

export type ConversationExportRecordPageOfListParamType = {
  current: number;
  size: number;
};

// 查询导出所有记录
export const conversationExportRecordPageOfList = (
  data: ConversationExportRecordPageOfListParamType,
) =>
  http(
    "post",
    "/chat-server/api/user/conversation-export-record/page-of-list",
    data,
  );

// 获取下载链接
export const conversationExportRecordDownloadUri = (id: string) =>
  http(
    "post",
    `/chat-server/api/user/conversation-export-record/download-uri/${id}`,
  );

// 获取快捷方式
export const chatFaqShortcut = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/faq/faq-record/short-cut`,
  );

// 会话默认页推荐prompt
export const getRecommendPrompt = () =>
  http(
    "get",
    `${isDesk() ? "/desk" : ""}/user/prompt-scene-content/recommended`,
  );

// 下载页数据请求
export const getAppDownload = () =>
  http("get", "/chat-server/api/system/app-download-img-url");

// app下载地址
export const getAppDownloadUrl = () =>
  http("get", "/chat-server/api/system/app-download-url");

export type GetDivideJoinHistoryListType = {
  current: number;
  size: number;
};

// 获取VIP分成邀请记录
export const getDivideJoinHistoryList = (data: GetDivideJoinHistoryListType) =>
  catch_http("post", "/chat-server/api/invitation/page-of-list", data);

export interface GetDivideHistoryListType extends GetDivideJoinHistoryListType {
  payConfigType: PayConfigTypeEnum;
}

// 获取VIP分成记录列表
export const getDivideHistoryList = (data: GetDivideHistoryListType) =>
  catch_http(
    "post",
    "/chat-server/api/invitation/divide-into/page-of-list",
    data,
  );

// 获取VIP分享面板数据
export const getDivideInfo = () =>
  catch_http("post", "/chat-server/api/invitation/code");

// 获取分成天数
export const getDivideDays = () =>
  catch_http("get", "/chat-server/api/invitation/divide-into/days");

// 获取分成通知
export const getDivideNotice = () =>
  catch_http(
    "post",
    "/chat-server/api/invitation/reward-remind",
    undefined,
    undefined,
    false,
  );

// 获取更新数据
export const checkUpdate = (platform: number) =>
  catch_http("post", "/chat-server/desk/version/newest", { platform });

// 统计APP安装
export enum statisticsTypeEnum {
  INSTALL = 1,
  ACCESS = 2,
  DOWNLOAD = 3,
}

export enum PlatformEnum {
  PWA = "pwa",
  WINDOWS = "windows",
  MACOS = "mac",
}

export const statisticsAppInstall = (
  userId: string,
  recordType: statisticsTypeEnum,
) =>
  catch_http("post", "/chat-server/pwa-install-record/record", {
    userId,
    recordType,
  });

// 桌面程序访问统计
export const statisticsDeskInstall = (
  userId: string,
  recordType: statisticsTypeEnum,
  platform: PlatformEnum,
) =>
  catch_http("post", "/chat-server/desk/pwa-install-record/record", {
    userId,
    recordType,
    platform,
  });

// 获取windows和macos更新数据
export const checkDeskUpdate = (platform: number, traceInfo?: string) => {
  const url = traceInfo
    ? "/chat-server/desk/version/newest?traceInfo=" + traceInfo
    : "/chat-server/desk/version/newest";
  return catch_http("post", url, { platform });
};

// 获取android更新地址
export const checkMobileUpdate = () =>
  catch_http("post", "/chat-server/app/version/newest", { platform: 0 });

// 获取会话tokens长度
export const gptTokens = () =>
  catch_http("get", "/chat-server/api/dictionary/gpt/tokens");

// 根据会话id获取会话信息
export const getChatInfoBySessionId = (id: string) =>
  catch_http("get", "/chat-server/api/share/v2/chat/" + id);

// 根据绘图id获取绘图信息
export const getPaintInfoBySessionId = (id: string) =>
  catch_http("get", "/chat-server/api/share/v2/paint/" + id);

// 获取服务配置的当前网站信息
export interface websiteConfigType {
  serverHost?: string;
}

export const getWebSiteConfig = () => {
  return catch_http<websiteConfigType>("get", "/chat-server/api/host/config");
};

//插件离线下载
export const pluginDownload = (category: "chrome" | "edge") =>
  catch_http("post", "/chat-server/plugin/version/offline-newest", {
    category,
  });

export const getDownloadQrcode = (platform: 0 | 1) =>
  catch_http("post", "/chat-server/app/version/get-qrcode", {
    platform,
  });

export const getFileUrl = (data: {
  fileDataId: string;
  fileId: string;
  sessionId: string;
}) => http("post", "/chat-server/api/file/data/operate/fileurl", data);

export const getFileMessageStatus = (
  data: { sessionId: string },
  config?: { cancelToken: CancelToken },
) =>
  http<any>(
    "post",
    `/chat-server/api/file/data/operate/result/message/${data.sessionId}`,
    data,
    config,
  );

export const updateLike = (data: { id: string; like: boolean }) =>
  http("post", "/chat-server/api/chat/msg/update-like", data);

export const cancelLike = (data: { id: string; like: boolean }) =>
  http("post", "/chat-server/api/chat/msg/cancel-like", data);

export const updatePaintLike = (data: { id: string; like: boolean }) =>
  http("post", "/chat-server/api/paint/msg/update-like", data);

export const cancelPaintLike = (data: { id: string; like: boolean }) =>
  http("post", "/chat-server/api/paint/msg/cancel-like", data);

// 获取小微论文链接
export const getSmallPaperUrl = () =>
  http<{ url: string }>("post", "/chat-server/api/dictionary/thesis/url");

// 获取场景菜单配置信息
export const getSceneConfig = () =>
  http<Array<{ code: string; name: string }>>(
    "get",
    "/chat-server/api/system/custom/scene/list",
  );

//获取站点属性
export const siteInfoCommon = (params: object) =>
  catch_http("post", "/chat-server/site/site-info/common-attribute", params);

//bilibili推广码转短码
export const bilibiliTrackConver = (trackId: string) =>
  catch_http(
    "get",
    "/chat-server/api/promotion/bilibili/track/convert?trackId=" + trackId,
  );

// 公开创作
export const requestOpenDisplay = (messageId: string, questionId: string) => {
  return httpPlus<void>("post", "/chat-server/api/chat/chat-display/add", {
    aiMessageId: messageId,
    targetId: questionId,
  });
};

// 取消公开创作
export const requestCloseDisplay = (messageId: string) => {
  return httpPlus<void>(
    "post",
    `/chat-server/api/chat/chat-display/delete/${messageId}`,
  );
};

// 添加最近使用
export const requestAddRecent = (params: {
  featureCode: string;
  featureType: string;
}) => {
  return httpPlus<void>("post", `/chat-server/api/feature/recent/add`, params);
};

// 论文摘要-列表
export const getAbstractList = (params: { current: number; size: number }) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/abstract/page`,
    params,
  );
};

// 论文续写-列表
export const getContinueList = (params: { current: number; size: number }) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/continue/page`,
    params,
  );
};

// 论文致谢-列表
export const getThanksList = (params: { current: number; size: number }) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/thanks/page`,
    params,
  );
};

// 论文提纲-列表
export const getOutlineList = (params: { current: number; size: number }) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/outline/page`,
    params,
  );
};

// 论文改写-列表
export const getRewriteList = (params: { current: number; size: number }) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/page`,
    params,
  );
};

// 论文摘要-删除
export const deletetePaperAbstract = (id: string) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/abstract/delete`,
    { id },
  );
};

// 论文续写-删除
export const deletetePaperContinue = (id: string) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/continue/delete`,
    { id },
  );
};

// 论文致谢-删除
export const deletetePaperThanks = (id: string) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/thanks/delete`,
    { id },
  );
};

// 论文提纲-删除
export const deletetePaperOutline = (id: string) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/outline/delete`,
    { id },
  );
};

// 论文改写- 删除
export const deletetePaperRewrite = (md5: string) => {
  return httpPlus<NCommon.RecordResponse<NLeftGeneralHistoryX.TRecord>>(
    "post",
    `/chat-server/api/paper/delete`,
    { md5 },
  );
};

// 论文摘要-详情
export const getAbstractInfo = (params: { id: string }) => {
  return httpPlus<NLeftGeneralHistoryX.TRecord>(
    "post",
    `/chat-server/api/paper/abstract/info`,
    params,
  );
};

// 论文续写-详情
export const getContinueInfo = (params: { id: string }) => {
  return httpPlus<NLeftGeneralHistoryX.TRecord>(
    "post",
    `/chat-server/api/paper/continue/info`,
    params,
  );
};

// 论文致谢-详情
export const getThanksInfo = (params: { id: string }) => {
  return httpPlus<NLeftGeneralHistoryX.TRecord>(
    "post",
    `/chat-server/api/paper/thanks/info`,
    params,
  );
};

// 论文提纲-详情
export const getOutlineInfo = (params: { id: string }) => {
  return httpPlus<NLeftGeneralHistoryX.TRecord>(
    "post",
    `/chat-server/api/paper/outline/info`,
    params,
  );
};

// 论文改写-详情
export const getRewriteInfo = (params: { md5: string }) => {
  return httpPlus<IRewriteDetail>(
    "post",
    `/chat-server/api/paper/info`,
    params,
  );
};
